AgrimarketControllers.controller('quoteController', ['$scope', '$rootScope', '$routeParams', '$http', '$filter', '$location', '$log', '$interval', 'currentUserFactory', 'configurationFactory',
     'parametersFactory', 'enumFactory', 'quoteService', 'colonnesQuote', 'colonnesQuoteWithoutRealTime',
    function ($scope, $rootScope, $routeParams, $http, $filter, $location, $log, $interval, currentUserFactory, configurationFactory, parametersFactory, enumFactory, quoteService, colonnesQuote, colonnesQuoteWithoutRealTime) {
        if (!$rootScope.ModuleCotation.Active && !$rootScope.ModuleHashtagri.Active)
            $location.path("/");

        $scope.Init = function (idMarketWsd) {
	        quoteService.GetLstMarketsCotationsDelayed(function(data, status) {
		        if (status !== null) {
			        if (status === 400) {
				        $scope.errorMessage = data[0].errors[0].Message;
			        } 
		        } else {
		        	$scope.lstMarketsAdmin = data;

		        	if (idMarketWsd)
		        		$scope.marketSelected = $filter('filter')($scope.lstMarketsAdmin, { IdMarketWSD: idMarketWsd }, true)[0];
		        	else
		        		$scope.marketSelected = $scope.lstMarketsAdmin[0];

		        	$scope.tableQuoteDataVisible = $rootScope.paramEuronextRoyaltyChoice == enumFactory.EuronextRoyalTy.Choice1 ? colonnesQuote.data.DataVisible.split(',') : colonnesQuoteWithoutRealTime.data.DataVisible.split(',');
		        	$scope.tableQuoteLibelle = $rootScope.paramEuronextRoyaltyChoice == enumFactory.EuronextRoyalTy.Choice1 ? colonnesQuote.data.ColumnName.split(',') : colonnesQuoteWithoutRealTime.data.ColumnName.split(',');
			        $scope.dataQuote = { reverse: true, sortKey: "IdExpiry" };
			        $scope.expirySelected = $scope.marketSelected.QuoteExpiries[0];
			        $scope.GetEuronextGraphData($scope.expirySelected);
			        if ($routeParams.withBtnBack)
				        $scope.withBtnBack = $routeParams.withBtnBack;
		        }
	        });
        };

        $rootScope.onRefresh = function () {
            $scope.Init();
        };

        $scope.SortQuote = function (sortKey, reverse) {
            $scope.dataQuote.sortKey = sortKey;
            $scope.dataQuote.reverse = reverse;
            $scope.marketSelected.QuoteExpiries = $filter('orderBy')($scope.marketSelected.QuoteExpiries, sortKey, reverse);
        };

        $scope.GetEuronextGraphData = function (expiry) {
	        if (!expiry)
	        	return;

            $scope.expirySelected = expiry;
            quoteService.GetSettleDataFromEuronext(expiry.IdExpiry, $scope.marketSelected.IdMarketWSD, false, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                }
                $scope.euronextValues = data;
                $scope.typeChart = enumFactory.TypeChart;
                $scope.typeChartSelected = $scope.typeChart[0].Value;
            });
        };

        $scope.SetClass = function (item, column) {
            var classes = "text_right ";

            if (column == "Buying" || column == "QtyBuy" || (column == "Variation" && item.Variation < 0))
                return classes + "negative_value";
            else if (column == "Sell" || column == "QtySell" || (column == "Variation" && item.Variation > 0))
                return classes + "positive_value";
            else if (column == "Variation" && item.Variation === 0)
                return classes + "neutral_value";
            return classes;
        };

        $rootScope.refreshCotations = $interval(function () {
	        quoteService.GetLstMarketsCotationsDelayed(function(data, status) {
		        if (status !== null) {
			        if (status === 400) {
				        $scope.errorMessage = data[0].errors[0].Message;
			        } 
		        } else
		        	$scope.lstMarketsAdmin = data;
	        });
        }, 60000 * 10); // 10 minutes

        $scope.$watchCollection("lstMarketsAdmin", function (newValue) {
        	if (newValue === undefined || newValue === null || $scope.lstMarketsAdmin.length === 0 || !$scope.marketSelected) return;
        	$scope.marketSelected = $filter('filter')($scope.lstMarketsAdmin, { IdMarketWSD: $scope.marketSelected.IdMarketWSD }, true)[0];
        });

        $scope.Init();
    }]);
