AgrimarketControllers.controller('userContractsSurfaceCommitmentMonitoringController', ['$scope', '$rootScope', '$routeParams', '$filter', 'configurationFactory',
    'contractsService', '$sce', 'colonnesContractsSurfaceCommitmentValidated',
    function ($scope, $rootScope, $routeParams, $filter, configurationFactory, contractsService, $sce, colonnesContractsSurfaceCommitmentValidated) {

        var init = function () {
            // Contracts engagement surface valider
            $scope.tableContractsSurfaceCommitmentValidatedDataVisible = colonnesContractsSurfaceCommitmentValidated.data.DataVisible.split(',');
            $scope.tableContractsSurfaceCommitmentValidatedLibelle = colonnesContractsSurfaceCommitmentValidated.data.ColumnName.split(',');

            // Gestion du trie de l'onglet Valider
            $scope.dataValidated = { reverse: true, sortKey: "IdContract" };

            // Pagination onglet Valider
            $scope.validatedPageNo = 1;

            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.max = 10;
            $scope.idCropSelected = $rootScope.crop.IdCrop;
            $scope.idProductSelected = "";
            $scope.idCompagnySelected = "";
            $scope.dateFilter = "";
            $scope.dateSelected = "";
            $scope.selectedClient = {};
            $scope.showDetailsValidated = true;
            $scope.viewMode = $routeParams.typeContract;
            $scope.lstProducts = [];
            $scope.listIdContractsToCover = [];
            $scope.allContractsInView = [];

            //Permet de gerer les arrondis des prix pour les decimales > 2
            $scope.$watch("allContractsInView", function (n, o) {
                if (n !== o) {
                    var regexfloat = /^[0-9]+.[0-9]{3,100}/;
                    n.forEach(function (item) {
                        if (regexfloat.test(item.Price))
                            item.Price = Number(item.Price).toFixed(2);
                    });
                }
            }, true);

            $scope.$watch("dateSelected", function (newValue) {
                if (newValue === "")
                    $scope.dateFilter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateFilter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.Filter();
            });

            $scope.GetFiltersWithContractsSurfaceCommitment();
        };

        var getContractsSurfaceCommitmentValidated = function () {
            $scope.loading = true;
            $scope.errorMessage = "";
            $scope.successMessage = "";
            if (!$scope.selectedIdContract) $scope.selectedIdContract = "";
            if (!$scope.selectedIdClient) $scope.selectedIdClient = "";
            contractsService.GetContractsSurfaceCommitmentValidatedPaginate($scope.idCropSelected, $scope.idProductSelected, $scope.dateFilter, $scope.selectedIdContract, "", $scope.idCompagnySelected, $scope.max, $scope.validatedPageNo, $scope.dataValidated.sortKey, $scope.dataValidated.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.totalValidated = data.Total;
                    $scope.contractsSurfaceCommitmentValidated = data.ListVm;

                    data.ListVm.forEach(function (contract) {
                        $scope.allContractsInView.push(contract);
                    });
                    $scope.loading = false;
                }
            });
        };

        $scope.GetFiltersWithContractsSurfaceCommitment = function () {
            $scope.loading = true;
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.idProductSelected = "";
            $scope.idCompagnySelected = "";
            $scope.selectedIdContract = "";
            $scope.dateSelected = "";
            $scope.selectedIdClient = "";
            $scope.All = $rootScope.editableObjectsFactory.EditableObjects.All.Content;
            contractsService.GetFiltersWithContractsSurfaceCommitmentByCrop($scope.idCropSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;
                        $scope.lstCompagnies = data.LstCompagnies;

                        $scope.lstCompagnies.unshift({ IdCompagny: "", Label: "Toutes" });

                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1 && $filter('filter')($scope.lstProducts, { IdProduct: "", ProductLabel: $scope.All }).length === 0)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: $scope.All });

                        $scope.Filter();

                    }
                }
            });
        };

        $scope.GetData = function (pageNo) {
            $scope.validatedPageNo = pageNo;
            getContractsSurfaceCommitmentValidated();
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.dataValidated.sortKey = sortKey;
            $scope.dataValidated.reverse = reverse;
            getContractsSurfaceCommitmentValidated();
        };

        $scope.Filter = function () {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            getContractsSurfaceCommitmentValidated();
        };

        $rootScope.onRefresh = function () {
            init();
        };

        $scope.AsChangedCrop = function () {
            init();
        };

        init();
    }]);
