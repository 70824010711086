AgrimarketControllers.controller('recommendationsController', [
    '$scope', '$sce', '$rootScope', 'userService', 'configurationFactory', 'uploadService', 'dateService', function ($scope, $sce, $rootScope, userService, configurationFactory, uploadService, dateService) {

        $scope.Init = function () {
            $scope.errorMessage = "";
            $scope.selectedAutoCompleteObject = {};
            $scope.selectedAutoCompleteInput = '';
            $scope.documentsfilter = {};
            $scope.prefixNumDocument = 'N° ';
            $scope.prefixDateUpdDateDocument = 'Le ';
            $scope.currentDocumentUrlPdf = null;
            $scope.keyMenu = 'Menu_User_Recommendation';

            $scope.search = {
                BeginDate: null,
                EndDate: null
            };

            userService.GetRecommendationsUser($rootScope.crop.IdCrop, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        var errorMessage = data[0].errors[0].Message + "</br>.";
                        $scope.errorMessage = $sce.trustAsHtml(errorMessage);
                    }
                } else {
                    $scope.lstDocuments = $scope.documentsfilter = data;
                }
            });
        };

        $scope.GetPdfDocument = function (item) {
            if (item) {
                $scope.idDocumentDisplay = item.IdDocument;
                $scope.currentDocumentUrlPdf = $sce.trustAsResourceUrl(item.Link);
            }
        };

        $scope.$watch("selectedAutoCompleteInput",
            function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    $scope.Filter();
                }
            });

        $scope.$watch(
            "search.BeginDate",
            function (newValue) {
                if (newValue === "" || newValue === undefined || newValue === null) {
                    $scope.dateFilterBegin = "";
                }
                else {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format dd/mm/yyyy.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilterBegin = converDate.format('DD/MM/YYYY');
                }
            });

        $scope.$watch(
            "search.EndDate",
            function (newValue) {
                if (newValue === "" || newValue === undefined || newValue === null) {
                    $scope.dateFilterEnd = "";
                }
                else {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format dd/mm/yyyy.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilterEnd = converDate.format('DD/MM/YYYY');
                }
            });

        $scope.$watch(
            "dateFilterBegin",
            function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    $scope.Filter();
                }
            });

        $scope.$watch(
            "dateFilterEnd",
            function (newValue) {
                if (newValue !== undefined && newValue !== null) {
                    $scope.Filter();
                }
            });



        $scope.Filter = function () {
            $scope.errorMessage = "";
            $scope.currentDocumentUrlPdf = null;
            var filterGeneral = angular.copy($scope.lstDocuments);

            if ($scope.selectedAutoCompleteInput !== undefined && $scope.selectedAutoCompleteInput !== null && $scope.selectedAutoCompleteInput !== "") {
                filterGeneral = filterGeneral.filter(function (element) {
                    if (checkElement(element.NumDocument, $scope.selectedAutoCompleteInput)) {
                        return element;
                    }
                    return null;
                });
            }
            if ($scope.dateFilterBegin !== "" && $scope.dateFilterBegin) {
                filterGeneral = filterGeneral.filter(function (element) {
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(element.DateDocument.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    var dateElementBegin = converDate.format('DD/MM/YYYY');

                    if (!dateService.compareDate(dateElementBegin, $scope.dateFilterBegin))
                        return element;
                    else
                        return null;
                });
            }

            if ($scope.dateFilterEnd !== "" && $scope.dateFilterEnd) {
                filterGeneral = filterGeneral.filter(function (element) {

                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(element.DateDocument.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    var dateElementEnd = converDate.format('DD/MM/YYYY');

                    if (!dateService.compareDate($scope.dateFilterEnd, dateElementEnd))
                        return element;
                    else
                        return null;
                });
            }
            $scope.documentsfilter = filterGeneral;
        };

        function checkElement(attr, string) {
            if (attr.indexOf(string) !== -1)
                return true;
            else return false;
        }

        $scope.AsChangedCrop = function () {
            $scope.Init();
        };

        $scope.Init();
    }]);