AgrimarketServices.service('recommandationsService', ['$rootScope', '$http', '$location', 'currentUserFactory', 'configurationFactory',
    function ($rootScope, $http, $location, currentUserFactory, configurationFactory) {

        var SendRequest = function (configfile, callback) {
            $http(configfile)
			    .success(function (data) {
			        callback(data, null, null, null);
			    })
			    .error(function (data, status, headers, config) {
			        callback(data, status, headers, config);
			    });
        };

        this.GetPreconisationAttachmentPathByIdAttachment = function (idAttachment, callback) {
            var config = {
                method: 'GET',
                url: configurationFactory.Url['Api'] + 'Preconisation/GetPreconisationAttachmentPathByIdAttachment',
                params: { idAttachment: idAttachment }
            };
            SendRequest(config, callback);
        };
    }]);