AgrimarketControllers.controller('userContractsOptionMonitoringController', ['$scope', '$rootScope', '$routeParams', 'configurationFactory',
    'contractsOptionService', '$sce', '$filter', 'colonnesContractsOptionExpired', 'colonnesContractsOptionPending',
    function ($scope, $rootScope, $routeParams, configurationFactory, contractsOptionService, $sce, $filter, colonnesContractsOptionExpired, colonnesContractsOptionPending) {

        var Init = function () {
            $scope.idCropSelected = $rootScope.crop.IdCrop;
            $scope.viewMode = $routeParams.typeContract;

            $scope.max = 10;

            $scope.dataSort = { reverse: true, sortKey: "IdContractOption" };

            $scope.expiredPageNo = 1;
            $scope.pendingPageNo = 1; 

            $scope.LabelAll = $rootScope.editableObjectsFactory.EditableObjects.All.Content;

            $scope.colonnesExpiredVisible = colonnesContractsOptionExpired.data.DataVisible.split(',');
            $scope.colonnesExpiredLibelle = colonnesContractsOptionExpired.data.ColumnName.split(',');

            $scope.colonnesPendingVisible = colonnesContractsOptionPending.data.DataVisible.split(',');
            $scope.colonnesPendingLibelle = colonnesContractsOptionPending.data.ColumnName.split(',');

            $scope.selectedIdContract = "";
            $scope.dateFilter = "";

            $scope.$watch("dateSelected", function (newValue) {
                if (newValue === "")
                    $scope.dateFilter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.dateFilter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateExecutedSelected", function (newValue) {
                if (newValue === "")
                    $scope.date2Filter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.date2Filter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateAbandonedSelected", function (newValue) {
                if (newValue === "")
                    $scope.date3Filter = "";
                else if (newValue) {
                    //permet de gérer l'internalisation de tout les formats des dates possibles.
                    //Au final nous faisons en sorte de la retrouvé au format yyyy/mm/dd pour l'envoyer à l'API.
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    $scope.date3Filter = converDate.format('YYYY/MM/DD');
                }
            });

            $scope.$watch("dateFilter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetContractsOptionWithFilter(1);
            });

            $scope.$watch("date2Filter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetContractsOptionWithFilter(1);
            });

            $scope.$watch("date3Filter", function (newValue, oldValue) {
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                    $scope.GetContractsOptionWithFilter(1);
            });

            GetContractsOptionFilters();
        };

        var GetContractsOptionFilters = function () {
            $scope.loading = true;
            $scope.idProductSelected = "";
            $scope.selectedIdContract = "";            
            $scope.dateSelected = "";
            $scope.dateExecutedSelected = "";
            $scope.dateAbandonedSelected = "";  
            $scope.idTypeOptionSelected = "";  
            
            contractsOptionService.GetFiltersWithContractsOptionByCrop($scope.idCropSelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    if (data) {
                        $scope.lstProducts = data.LstProductFilterVm;   
                        $scope.lstTypeOption = data.LstTypeOptionFilterVm;

                        if ($scope.lstProducts.length === 1)
                            $scope.idProductSelected = $scope.lstProducts[0].IdProduct;
                        else if ($scope.lstProducts.length > 1)
                            $scope.lstProducts.unshift({ IdProduct: "", ProductLabel: $scope.LabelAll });     

                        if ($scope.lstTypeOption.length === 1)
                            $scope.idTypeOptionSelected = $scope.lstTypeOption[0].IdTypeOption;
                        else if ($scope.lstTypeOption.length > 1 && $filter('filter')($scope.lstTypeOption, { IdTypeOption: "", TypeOptionLabel: $rootScope.globalLabels.AllItems }).length === 0)
                            $scope.lstTypeOption.unshift({ IdTypeOption: "", TypeOptionLabel: $rootScope.globalLabels.AllItems });      

                        $scope.GetContractsOptionWithFilter(1);
                    }
                }
            });
        };

        $scope.GetContractsOptionWithFilter = function (pagenum) {
            $scope.loading = true;
            if ($scope.viewMode === "expired")              
                contractsOptionService.GetMonitoringContractOptionExpiredPaginate( $scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOptionSelected, $scope.dateFilter, $scope.date2Filter, $scope.date3Filter, $scope.selectedIdContract, "", "", $scope.max, pagenum, $scope.dataSort.sortKey, $scope.dataSort.reverse, function (data, status) {                
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.totalExpired = data.Total;
                        $scope.contractsExpired = data.ListVm;
                        $scope.loading = false;
                    }
                });
            else if ($scope.viewMode === "pending")
                contractsOptionService.GetMonitoringContractOptionPendingPaginate( $scope.idCropSelected, $scope.idProductSelected, $scope.idTypeOptionSelected, $scope.dateFilter, $scope.date2Filter, $scope.date3Filter, $scope.selectedIdContract, "", "", $scope.max, pagenum, $scope.dataSort.sortKey, $scope.dataSort.reverse, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        $scope.totalPending = data.Total;
                        $scope.contractsPending = data.ListVm;
                        $scope.loading = false;
                    }
                });
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.dataSort.sortKey = sortKey;
            $scope.dataSort.reverse = reverse;
            $scope.GetContractsOptionWithFilter(1);
        };

        $rootScope.onRefresh = function () {
            Init();
        };

        $rootScope.AsChangedCrop = function () {
            Init();
        };

        Init();
    }]);
 