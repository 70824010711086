AgrimarketControllers.controller('billsController', [
    '$scope', '$rootScope', '$filter', '$location', 'colonnes', 'billsService', 'configurationFactory', 'parametersFactory', 'currentUserFactory', 'enumFactory',
    function ($scope, $rootScope, $filter, $location, colonnes, billsService, configurationFactory, parametersFactory, currentUserFactory, enumFactory) {

        if (!$rootScope.ModuleMyAccount.Active)
            $location.path('/');


        var init = function () {
            $scope.labelStatusBills = [];
            $scope.bBillsNoOutstandingsIsPayed = parametersFactory.Parameters.bBillsNoOutstandingsIsPayed.Value === "1";
            $scope.labelStatusBills.push(
                {
                    Label: $rootScope.editableObjectsFactory.EditableObjects.Bills_Label_Status_All.Content,
                    Value: ""
                }
            );
            $scope.labelStatusBills.push(
                {
                    Label: $rootScope.editableObjectsFactory.EditableObjects.Bills_Label_Status_IsPayed.Content,
                    Value: enumFactory.StatusBills.IsPayed
                }
            );
            $scope.labelStatusBills.push(
                {
                    Label: $rootScope.editableObjectsFactory.EditableObjects.Bills_Label_Status_IsNotPayed.Content,
                    Value: enumFactory.StatusBills.IsNotPayed
                }
            );
            if (!$scope.bBillsNoOutstandingsIsPayed)
                $scope.labelStatusBills.push(
                    {
                        Label: $rootScope.editableObjectsFactory.EditableObjects.Bills_Label_Status_Undetermined.Content,
                        Value: enumFactory.StatusBills.Undetermined
                    }
                );

            $scope.searchText = "";
            $scope.errorMessage = "";
            $scope.iCurrent = 1;
            $scope.typeBills = [];
            $scope.tableBillsDataVisible = colonnes.data.DataVisible.split(',');
            $scope.tableBillsLabels = colonnes.data.ColumnName.split(',');
            $scope.data = { reverse: true, sortKey: "Date" };
            $scope.pageNo = 1;
            $scope.max = 10;
            $scope.All = $rootScope.editableObjectsFactory.EditableObjects.AllItems_F.Content;

            $scope.GetFilters();

            $scope.startDateFilter = "";
            $scope.endDateFilter = "";

            $scope.WatchStartDateFilterAndEndDateFilter("startDateSelected");
            $scope.WatchStartDateFilterAndEndDateFilter("endDateSelected");
        };

        $scope.WatchStartDateFilterAndEndDateFilter = function (target1) {
            $scope.$watch(target1, function (newValue, oldValue) {
                if (newValue === "") {
                    if (target1 === "startDateSelected")
                        $scope.startDateFilter = "";
                    if (target1 === "endDateSelected")
                        $scope.endDateFilter = "";
                }
                else if (newValue) {
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    if (target1 === "startDateSelected")
                        $scope.startDateFilter = converDate.format('YYYY/MM/DD');
                    else if (target1 === "endDateSelected")
                        $scope.endDateFilter = converDate.format('YYYY/MM/DD');
                }
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue)) {
                    $scope.GetBillsPaginateWithFilter();
                }
            });
        };

        $scope.ExportBills = function () {
            var downloadPath = billsService.GetBillsXls($scope.statusBillsSelected ? $scope.statusBillsSelected.Value : "", $scope.idTypeBillSelected, currentUserFactory.User.IdClientSelectedFromTc ? currentUserFactory.User.IdClientSelectedFromTc : currentUserFactory.User.IdUser, $scope.idCompagnySelected, $scope.startDateFilter, $scope.endDateFilter, $scope.searchText, $rootScope.language.IdLanguage);
            window.open(downloadPath, '_blank', '');
        };


        $scope.AsChangedStatusBills = function () {
            $scope.GetBillsPaginateWithFilter();
        };

        $rootScope.onRefresh = function () {
            init();
        };

        $scope.GetFilters = function () {
            billsService.GetFilters(function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.compagnies = data.compagnies;
                    $scope.compagnies.forEach(function (item) {
                        if (!item.Id)
                            item.Id = "";
                    });

                    if ($scope.compagnies.length > 1)
                        $scope.compagnies.unshift({ Id: -1, Label: $scope.All });

                    var compagnieIsDefault = $scope.compagnies.find(function (compagnie) {
                        return compagnie.IsDefault === true;
                    });

                    if ($scope.compagnies.length > 0) {
                        if (compagnieIsDefault)
                            $scope.idCompagnySelected = compagnieIsDefault.Id;
                        else
                            $scope.idCompagnySelected = $scope.compagnies[0].Id;
                    }

                    $scope.typeBills = data.typeBills;
                    if ($scope.typeBills.length > 0) {
                        $scope.idTypeBillSelected = $scope.typeBills[0].Id;
                        $scope.GetBillsPaginateWithFilter();
                    }
                }
            });
        };

        $scope.GetBillsPaginateWithFilter = function () {
            if (!$scope.idTypeBillSelected)
                return;
		    $rootScope.loading = true;
            $scope.lstBills = [];
            billsService.GetBillsPaginateWithFilter($scope.statusBillsSelected ? $scope.statusBillsSelected.Value : "", $scope.startDateFilter ? $scope.startDateFilter : "", $scope.endDateFilter ? $scope.endDateFilter : "", $scope.idTypeBillSelected, $scope.idCompagnySelected ? $scope.idCompagnySelected : "", $scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
				if (status !== null) {
					if (status === 400)
						$scope.errorMessage = data[0].errors[0].Message;
				} else {
					$scope.lstBills = data.ListVm;
					$scope.total = data.Total;
				}
			    $rootScope.loading = false;
			});
		};

        $scope.ChangeTypeBill = function (idTypeBill) {
            $scope.idTypeBillSelected = idTypeBill;
            $scope.GetBillsPaginateWithFilter();
        };
        
        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.GetBillsPaginateWithFilter();
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetBillsPaginateWithFilter();
        };

        $scope.$watch("searchText", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetBillsPaginateWithFilter();
        });

        init();
    }
]);