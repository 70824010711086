AgrimarketControllers.controller('deliveryController', [
    '$scope', '$rootScope', '$location', '$filter', 'deliveriesService', 'parametersFactory', 'configurationFactory', 'colonnes', 'currentUserFactory',
    function ($scope, $rootScope, $location, $filter, deliveriesService, parametersFactory, configurationFactory, colonnes, currentUserFactory) {

        $scope.FamilyArticleInDeliveriesAndCommands = (parametersFactory.Parameters.FamilyArticleInDeliveriesAndCommands.Value === "1");

        if (!$rootScope.ModuleMyAccount.Active)
            $location.path('/login');

        $scope.isDisplayPDFColumn = (parametersFactory.Parameters.bDisplayPDFDelivery.Value === '1' && parametersFactory.Parameters.bUsePdfProjDirect.Value === '1');

        $scope.dateStartSelected = "";
        $scope.dateEndSelected = "";

        $scope.ApplyDeliveryDates = function () {
            Init();
        };

        var Init = function () {
            $scope.groupArticle = [];
            $scope.buttonDeliveryDetails = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Delivery_Button_Details.Content;
            $scope.otherArticles = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_Label_Search_OtherArticles.Content;
            $scope.qtyOrdered = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Commands_Label_QtyOrdered.Content;

            $scope.orderDate = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Delivery_Label_Date.Content;
            $scope.orderNumber = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Delivery_Label_Number.Content;
            $scope.deliveryState = $rootScope.editableObjectsFactory.EditableObjects.User_Supply_Delivery_Label_State.Content;
            $scope.operations = {};
            $scope.idOperationSelected = null;
            $scope.searchText = "";
            $scope.deliveryCurrent = "";
            $scope.familyArticles = [];
            $scope.idDeliveryStateSelected = 0;
            $scope.max = 10;
            $scope.tableDeliveriesDatasVisible = colonnes.data.DataVisible.split(',');
            $scope.tableDeliveriesLabels = colonnes.data.ColumnName.split(',');
            $scope.data = { reverse: true, sortKey: "LineNumber" };
            $scope.pageNo = 1;
            $scope.All_F = $rootScope.editableObjectsFactory.EditableObjects.AllItems_F.Content;
            $scope.All = $rootScope.editableObjectsFactory.EditableObjects.All.Content;

            $scope.GetFiltersByDates();
        };

        $scope.showDetailsArticle = function (delivery) {
            $scope.total = delivery ? delivery.Articles.length : 0;
            $scope.deliveryCurrent = delivery;
            $scope.groupArticle.forEach(function (item) {
                if (item.OrderNumber === delivery.OrderNumber)
                    delivery.ShowTableDetail = delivery.ShowTableDetail === undefined ? true : !delivery.ShowTableDetail;
                else
                    item.ShowTableDetail = false;
            });
        };

        $scope.ExportDeliveries = function () {
            var downloadPath = deliveriesService.GetDeliveriesXls(toDate($scope.dateStartSelected), toDate($scope.dateEndSelected), $scope.idOperationSelected, currentUserFactory.User.IdClientSelectedFromTc ? currentUserFactory.User.IdClientSelectedFromTc : currentUserFactory.User.IdUser, $scope.idCompagnySelected, $rootScope.language.IdLanguage);
            window.open(downloadPath, '_blank', '');
        };

        $scope.GetFiltersByDates = function () {
            deliveriesService.GetFiltersByDates(toDate($scope.dateStartSelected), toDate($scope.dateEndSelected), function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {

                    $scope.dateStartSelected = data.DateStart;
                    $scope.dateEndSelected = data.DateEnd;

                    $scope.deliveryStates = data.DeliveryStates;
                    if ($scope.deliveryStates.length > 1)
                        $scope.deliveryStates.unshift({ Id: 0, Label: $scope.All });
                    if ($scope.deliveryStates.length > 0)
                        $scope.idDeliveryStateSelected = $scope.deliveryStates[0].Id;

                    $scope.compagnies = data.Compagnies;
                    $scope.compagnies.forEach(function (item) {
                        if (!item.Id)
                            item.Id = "";
                    });

                    if ($scope.compagnies.length > 1)
                        $scope.compagnies.unshift({ Id: -1, Label: $scope.All_F });

                    var compagnieIsDefault = $scope.compagnies.find(function (compagnie) {
                        return compagnie.IsDefault === true;
                    });

                    if ($scope.compagnies.length > 0) {
                        if (compagnieIsDefault)
                            $scope.idCompagnySelected = compagnieIsDefault.Id;
                        else
                            $scope.idCompagnySelected = $scope.compagnies[0].Id;
                    }

                    $scope.GetOperationDeliveries();
                }
            });
        };

        $scope.GetOperationDeliveries = function () {
            $scope.errorMessage = "";
            $scope.loadingOperations = true;
            deliveriesService.GetOperationDeliveries(toDate($scope.dateStartSelected), toDate($scope.dateEndSelected), $scope.idCompagnySelected ? $scope.idCompagnySelected : "", function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.operations = data;
                    if ($scope.operations.length > 0) {
                        $scope.idOperationSelected = $scope.operations[0].IdOperation;
                        if ($scope.idOperationSelected) {
                            $scope.GetDeliveriesPaginateWithFilter();
                        }
                    }
                    $scope.loadingOperations = false;
                }
            });
        };

        $scope.GetDeliveriesPaginateWithFilter = function () {
            $scope.errorMessage = "";
            $scope.groupArticle = [];
            $scope.loadingDeliveries = true;
            deliveriesService.GetDeliveriesPaginateWithFilter(toDate($scope.dateStartSelected), toDate($scope.dateEndSelected), $scope.idOperationSelected, $scope.idCompagnySelected ? $scope.idCompagnySelected : "", $scope.data.sortKey, $scope.data.reverse, $scope.pageNo, $scope.max, $scope.searchText, $scope.idDeliveryStateSelected, $scope.deliveryCurrent ? $scope.deliveryCurrent.OrderNumber : "", function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $scope.errorMessage = data;
                }
                else {
                    $scope.groupArticle = data;
                    if ($scope.deliveryCurrent) {
                        var deliveryCurrent = $filter('filter')($scope.groupArticle, { OrderNumber: $scope.deliveryCurrent.OrderNumber }, true)[0];
                        if (deliveryCurrent)
                            deliveryCurrent.ShowTableDetail = true;
                    }
                    $scope.loadingDeliveries = false;
                }
            });
        };

        $scope.ChangeOperation = function (idOperation) {
            $scope.idOperationSelected = idOperation;
            $scope.GetDeliveriesPaginateWithFilter();
        };

        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.GetDeliveriesPaginateWithFilter();
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetDeliveriesPaginateWithFilter();
        };

        $scope.$watch("searchText", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetDeliveriesPaginateWithFilter();
        });

        function toDate(date) {
            if (!date) return "";
            var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
            var dt = new Date(date.replace(pattern, '$3-$2-$1'));
            var currentDate = new Date(dt.toString());
            return moment(currentDate).format('YYYY/MM/DD');
        }

        Init();
    }
]);