AgrimarketControllers.controller('contributionsController', [
    '$scope', '$rootScope', '$location', 'colonnes', 'contributionsService', 'configurationFactory', 'parametersFactory', 'currentUserFactory',
    function ($scope, $rootScope, $location, colonnes, contributionsService, configurationFactory, parametersFactory, currentUserFactory) {

        if (!$rootScope.ModuleMyAccount.Active)
            $location.path('/');

        $scope.isDisplayPDFColumn = (parametersFactory.Parameters.bDisplayPDFContribution.Value === '1');

        $scope.AsChangedCrop = function () {
            Init();
        };

        var Init = function () {
            $scope.contributions = [];
            $scope.tableDatasVisiblpypeContributions = [];
            $scope.products = [];
            $scope.productSelected = 0;
            $scope.operations = [];
            $scope.idOperationSelected = 0;
            $scope.labeloperationselected = "";
            $scope.valuespecificrows = {};
            $scope.valuespecificrows.DeliveryWeight = 0;
            $scope.valuespecificrows.NormedWeight = 0;
            $scope.totalOutstandings = 0;
            $scope.max = 10;
            $scope.searchText = "";
            $scope.errorMessage = "";
            $scope.showTabsAll = false;
            $scope.tableContributionsDatasVisible = colonnes.data.DataVisible.split(',');
            $scope.tableContributionsLabels = colonnes.data.ColumnName.split(',');
            $scope.data = { reverse: true, sortKey: "DateFormatDate" };
            $scope.pageNo = 1;
            $scope.All = $rootScope.editableObjectsFactory.EditableObjects.AllItems_F.Content;
            $scope.GetFiltersByIdCrop($rootScope.crop.IdCrop);

            $scope.startDateFilter = "";
            $scope.endDateFilter = "";

            $scope.WatchStartDateFilterAndEndDateFilter("startDateSelected");
            $scope.WatchStartDateFilterAndEndDateFilter("endDateSelected");
        };

        $scope.WatchStartDateFilterAndEndDateFilter = function (target1) {
            $scope.$watch(target1, function (newValue, oldValue) {
                if (newValue === "") {
                    if (target1 === "startDateSelected")
                        $scope.startDateFilter = "";
                    if (target1 === "endDateSelected")
                        $scope.endDateFilter = "";
                }
                else if (newValue) {
                    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                    var dt = new Date(newValue.replace(pattern, '$3-$2-$1'));
                    var currentDate = new Date(dt.toString());
                    var converDate = moment(currentDate);
                    if (target1 === "startDateSelected")
                        $scope.startDateFilter = converDate.format('YYYY/MM/DD');
                    else if (target1 === "endDateSelected")
                        $scope.endDateFilter = converDate.format('YYYY/MM/DD');
                }
                if ((newValue && oldValue !== newValue) || (!newValue && oldValue)) {
                    $scope.GetTypeOperation($scope.startDateFilter ? $scope.startDateFilter : "", $scope.endDateFilter ? $scope.endDateFilter : "", $rootScope.crop.IdCrop, $scope.productSelected, $scope.idCompagnySelected);
                }
            });
        };

        $scope.ExportContributions = function () {
            var downloadPath = contributionsService.GetContributionsXls($rootScope.crop.IdCrop, $scope.idOperationSelected ? $scope.idOperationSelected : "", currentUserFactory.User.IdClientSelectedFromTc ? currentUserFactory.User.IdClientSelectedFromTc : currentUserFactory.User.IdUser, $scope.productSelected ? $scope.productSelected : "", $scope.idCompagnySelected, $scope.startDateFilter, $scope.endDateFilter, $scope.searchText, $rootScope.language.IdLanguage);
            window.open(downloadPath, '_blank', '');
        };

        $scope.GetFiltersByIdCrop = function (idCrop) {
            contributionsService.GetFiltersByIdCrop(idCrop, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $location.path('/');
                }
                else {
                    $scope.compagnies = data.Compagnies;
                    $scope.compagnies.forEach(function (item) {
                        if (!item.Id)
                            item.Id = "";
                    });

                    if ($scope.compagnies.length > 1)
                        $scope.compagnies.unshift({ Id: -1, Label: $scope.All });

                    var compagnieIsDefault = $scope.compagnies.find(function (compagnie) {
                        return compagnie.IsDefault === true;
                    });

                    if ($scope.compagnies.length > 0) {
                        if (compagnieIsDefault)
                            $scope.idCompagnySelected = compagnieIsDefault.Id;
                        else
                            $scope.idCompagnySelected = $scope.compagnies[0].Id;
                    }

                    $scope.products = data.Products;
                    if ($scope.products.length > 0) {
                        // $scope.productSelected = $scope.products[0].Id;
                        $scope.GetTypeOperation($scope.startDateFilter ? $scope.startDateFilter : "", $scope.endDateFilter ? $scope.endDateFilter : "", idCrop, $scope.productSelected, $scope.idCompagnySelected);
                    }
                    else {
                        $scope.listContributions = [];
                        $scope.total = 0;
                        $scope.firstTotalIndex = -1;
                        $scope.secondTotalIndex = -1;
                    }
                }
            });
        };

        $scope.GetTypeOperation = function (startDate, endDate, idCrop, idProduct, idCompagny) {
            if (!idCompagny)
                idCompagny = "";

            if (!idProduct)
                idProduct = "";

            contributionsService.GetTypeOperations(startDate, endDate, idCrop, idProduct, idCompagny, function (data, status) {
                if (status !== null) {
                    if (status == 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $location.path('/');
                }
                else {
                    $scope.operations = data;
                    if ($scope.operations.length > 0) {
                        $scope.idOperationSelected = $scope.operations[0].IdOperation;
                        $scope.labeloperationselected = $scope.operations[0].Label.toLowerCase();
                        $scope.showTabsAll = $scope.operations.length > 1 && parametersFactory.Parameters.IsTerrenaGroup.Value !== '1';
                        $scope.GetContributionsPaginateWithFilter();
                    }
                    else {
                        $scope.listContributions = [];
                        $scope.total = 0;
                        $scope.indexTotalDeliveryWeight = -1;
                        $scope.indexTotalNormedWeight = -1;
                    }
                }
            });
        };

        $scope.GetContributionsPaginateWithFilter = function () {
            contributionsService.GetContributionsPaginateWithFilter($scope.startDateFilter ? $scope.startDateFilter : "", $scope.endDateFilter ? $scope.endDateFilter : "", $rootScope.crop.IdCrop, $scope.productSelected ? $scope.productSelected : "", $scope.idCompagnySelected ? $scope.idCompagnySelected : "", $scope.idOperationSelected ? $scope.idOperationSelected : "", $scope.searchText, $scope.max, $scope.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                    else
                        $location.path('/');
                }
                else {
                    $scope.listContributions = data.ListVm;
                    $scope.total = data.Total;

                    var indexTotalDeliveryWeight = $scope.tableContributionsDatasVisible.indexOf("DeliveryWeight");
                    var indexTotalNormedWeight = $scope.tableContributionsDatasVisible.indexOf("NormedWeight");

                    if (indexTotalDeliveryWeight === -1 && indexTotalNormedWeight !== -1) {
                        $scope.firstTotalIndex = indexTotalNormedWeight;
                        $scope.firstTotalValue = data.TotalNormedWeight;
                    }
                    else if (indexTotalNormedWeight === -1 && indexTotalDeliveryWeight !== -1) {
                        $scope.firstTotalIndex = indexTotalDeliveryWeight;
                        $scope.firstTotalValue = data.TotalDeliveryWeight;
                    }
                    else if (indexTotalNormedWeight !== -1 && indexTotalDeliveryWeight !== -1) {
                        if (indexTotalDeliveryWeight < indexTotalNormedWeight) {
                            $scope.firstTotalIndex = indexTotalDeliveryWeight;
                            $scope.firstTotalValue = data.TotalDeliveryWeight;
                            $scope.secondTotalIndex = indexTotalNormedWeight;
                            $scope.secondTotalValue = data.TotalNormedWeight;
                        }
                        else {
                            $scope.firstTotalIndex = indexTotalNormedWeight;
                            $scope.firstTotalValue = data.TotalNormedWeight;
                            $scope.secondTotalIndex = indexTotalDeliveryWeight;
                            $scope.secondTotalValue = data.TotalDeliveryWeight;
                        }
                    }
                }
            });
        };

        $scope.ChangeOperation = function (idOperation, label) {
            $scope.contributions = [];
            $scope.idOperationSelected = idOperation;
            $scope.labeloperationselected = label ? label.toLowerCase() : '';
            $scope.GetContributionsPaginateWithFilter();
        };

        $scope.ChangeFilters = function () {
            $scope.contributions = [];
            $scope.operations = [];
            $scope.idOperationSelected = 0;
            $scope.max = 10;
            $scope.searchText = "";
            $scope.errorMessage = "";
            $scope.GetTypeOperation($scope.startDateFilter ? $scope.startDateFilter : "", $scope.endDateFilter ? $scope.endDateFilter : "", $rootScope.crop.IdCrop, $scope.productSelected, $scope.idCompagnySelected);
        };

        $scope.GetData = function (pageNo) {
            $scope.pageNo = pageNo;
            $scope.GetContributionsPaginateWithFilter();
        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.GetContributionsPaginateWithFilter();
        };

        $scope.$watch("searchText", function (newValue, oldValue) {
            if ((newValue && oldValue !== newValue) || (!newValue && oldValue))
                $scope.GetContributionsPaginateWithFilter();
        });

        Init();
    }
]);