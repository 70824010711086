AgrimarketControllers.controller('marketinfostUserController', ['$scope', '$rootScope', 'marketinfosUserHashtagriService', '$location', '$q', '$filter', 'quoteService', 'quoteCashHashtagriService', 'quoteForexHashtagriService', 'parametersFactory', 'blocDispositionService', 'enumFactory', '$sce',
    function ($scope, $rootScope, marketinfosUserHashtagriService, $location, $q, $filter, quoteService, quoteCashHashtagriService, quoteForexHashtagriService, parametersFactory, blocDispositionService, enumFactory, $sce) {

        $scope.userNewsHashtagri = [];
        $scope.userNewsInfoOs = {};
        $scope.userNewsHashtagriErrorMessage = "";
        $scope.userCategoryInfoMarketsErrorMessage = "";
        $scope.userCategoryInfoMarkets = [];
        $scope.contentMarketInfosCategHashtagri = [];
        $scope.contentMarketInfoCategOS = {};
        $scope.displayModeWithoutDateFilterListing = false;
        $scope.displayContentInfoMarketOS = false;
        $scope.displayNewsInfoMarketOS = false;
        $scope.showNewsInfoOs = false;
        $scope.contentBlocDispositions = enumFactory.ContentBlocDispositions;
        $scope.GetNewsHashtagriFromWsdApi = function () {
            var defered = $q.defer();
            marketinfosUserHashtagriService.GetNewsHashtagriFromWsdApi(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        return defered.promise(data[0].errors[0].Message);                  
                } else {
                    defered.resolve(data);
                }
            });
            return defered.promise;
        };

        $scope.showInfoOs = function () {
            $scope.showNewsInfoOs = !$scope.showNewsInfoOs;
        };

        $scope.GetNewsContentInfoMarketOS = function () {
            var defered = $q.defer();
            marketinfosUserHashtagriService.GetContentInfoMarketOS(enumFactory.TypeBlockEditables.Bandeau, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        return defered.promise(data[0].errors[0].Message);
                } else {
                    defered.resolve(data);
                }
            });
            return defered.promise;
        };

        $scope.GetCategoryInfoMarkets = function () {
            var defered = $q.defer();
            marketinfosUserHashtagriService.GetCategoryInfoMarkets(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        return defered.promise(data[0].errors[0].Message);
                } else {
                    defered.resolve(data);
                }
            });
            return defered.promise;
        };

        $scope.GetMarketInfosCategHashtagriFromWsdApi = function (item) {
            if (item) {
                if (item.IdCategoryInfoMarket != enumFactory.TypeCategoryInfoMarket.INFOOS) {
                    marketinfosUserHashtagriService.GetMarketInfosCategHashtagriFromWsdApi(item.IdNewsCategWSDSynthesis, item.IdNewsCategWSDTechnically ? item.IdNewsCategWSDTechnically : '', function (data, status) {
                        if (status !== null) {
                            if (status === 400)
                                $scope.userCategoryInfoMarketsErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                        } else {
                            $scope.contentMarketInfosCategHashtagri = data;

                            if (data && data.DatesNewsSynthesis && data.DatesNewsSynthesis.length > 0) {
                                $scope.DisplayNewsSynthesis($scope.contentMarketInfosCategHashtagri.DatesNewsSynthesis[0].Value);
                            }

                            if (data && data.DatesTechnicallyPoint && data.DatesTechnicallyPoint.length > 0)
                                $scope.DisplayTechnicallyPoint($scope.contentMarketInfosCategHashtagri.DatesTechnicallyPoint[0].Value);

                            if ((data && data.NewsSynthesis && data.NewsSynthesis.length > 0) && ((!data.DatesNewsSynthesis || data.DatesNewsSynthesis.length === 0) && (!data.DatesTechnicallyPoint || data.DatesTechnicallyPoint.length === 0)))
                                $scope.displayModeWithoutDateFilterListing = true;
                            else
                                $scope.displayModeWithoutDateFilterListing = false;
                        }

                        $scope.displayContentInfoMarketOS = false;
                    });
                } else {
                    marketinfosUserHashtagriService.GetContentInfoMarketOS(enumFactory.TypeBlockEditables.TAB, function (data, status) {
                        if (status !== null) {
                            if (status === 400)
                                return defered.promise(data[0].errors[0].Message);
                        } else {
                            $scope.contentMarketInfoCategOS = data;

                            if (data && data.DatesPublications && data.DatesPublications.length > 0) {
                                $scope.DisplayContentInfoMarketOS($scope.contentMarketInfoCategOS.DatesPublications[0].Value);
                                $scope.displayContentInfoMarketOS = true;
                            } else
                                $scope.displayContentInfoMarketOS = false;

                            $scope.contentMarketInfosCategHashtagri = [];
                            $scope.displayModeWithoutDateFilterListing = false;
                        }
                    });
                }
                $scope.idCategoryInfoMarketSelected = item.IdCategoryInfoMarket;
            }
        };

        $scope.DisplayNewsSynthesis = function (value) {
            $scope.contentMarketInfosCategHashtagri.NewsSynthesis.forEach(function (item) {
                item.ShowNewsSynthesis = item.DateUUID === value || item.DateString === value;
            });
        };

        $scope.DisplayTechnicallyPoint = function (value) {
            $scope.contentMarketInfosCategHashtagri.TechnicallyPoints.forEach(function (item) {
                item.ShowTechnicallyPoint = item.DateUUID === value || item.DateString === value;
            });
        };

        $scope.setGlobalMacroActiveGraph = function (activeGraph, idExpiry) {
            $scope.globalMacro.activeGraph = activeGraph;
            $scope.globalMacro.dataChartSeries = [];

            if (activeGraph === 'PetrolBrent') {
                $scope.globalMacro.unit = $scope.globalMacro.PetrolBrentUnit;
                //199  --> IDMARKET WSD PETROL BRENT
                quoteService.GetSettleDataFromEuronext(idExpiry, 199, true, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.globalMacroError = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        var series = [];

                        data.forEach(function (item) {
                            var date = new Date(parseInt(item.DateTimeSpan));
                            series.push([Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), item.Settle]);
                        });
                        $scope.globalMacro.dataChartSeries = series;
                    }
                });
            } else {
                $scope.globalMacro.unit = $scope.globalMacro.ForexEurUsdUnit;
                //1 --> IDForex Eur USD
                quoteForexHashtagriService.GetHistoForexByIdForexFromWsdApi(1, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.globalMacroError = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        var series = [];

                        data.forEach(function (item) {
                            var date = new Date(parseInt(item.DateTimeSpan));
                            series.push([Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), item.Settle]);
                        });
                        $scope.globalMacro.dataChartSeries = series;
                    }
                });
            }
        };

        $scope.setFutureProductsActiveIdExpiry = function (idExpiry, idMarket) {
            if ($scope.futureProductsActiveIdExpiry === idExpiry) {
                $scope.futureProductsActiveIdExpiry = 0;
            } else {
                $scope.futureProductsActiveIdExpiry = idExpiry;
                quoteService.GetSettleDataFromEuronext(idExpiry, idMarket, true, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.futureProductsError = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        var futureProduct = $filter('filter')($scope.futureProducts, { idProduct: idMarket }, true)[0];
                        var futureExpiry = $filter('filter')(futureProduct.expiries, { idExpiry: idExpiry }, true)[0];
                        var series = [];
                        data.forEach(function (item) {
                            var date = new Date(parseInt(item.DateTimeSpan));
                            series.push([Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), item.Settle, new Date()]);
                        });
                        futureExpiry.dataChartSeries = series;
                    }
                });
            }
        };

        $scope.setPhysiqueProductsActiveIdExpiry = function (idExpiry, idProduct) {

            if ($scope.physiqueProductsActiveIdExpiry === idExpiry) {
                $scope.physiqueProductsActiveIdExpiry = 0;
            } else {
                $scope.physiqueProductsActiveIdExpiry = idExpiry;

                quoteCashHashtagriService.GetHistoCashForGraphByIdExpiryFromWsdApi(idExpiry, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.physiqueProductsError = $sce.trustAsHtml(data[0].errors[0].Message);
                    } else {
                        var physiqueProduct = $filter('filter')($scope.physiqueProducts, { idProduct: idProduct }, true)[0];
                        var physiqueMarketExpiry = $filter('filter')(physiqueProduct.marketsExpiry, { idExpiry: idExpiry }, true)[0];
                        var series = [];
                        data.forEach(function (item) {
                            var date = new Date(parseInt(item.DateDayTimeSpan));
                            series.push([Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), item.settle, new Date()]);
                        });
                        physiqueMarketExpiry.dataChartSeries = series;
                    }
                });
            }
        };

        var setFutureMultiSelectValues = function () {
            $scope.futureProductLabel = "Sélectionnez un produit";
            $scope.futureProductsMenuItems.forEach(function (futureProductsMenuItem) {
                if (futureProductsMenuItem.checked) {
                    if ($scope.futureProductLabel === "Sélectionnez un produit") {
                        $scope.futureProductLabel = futureProductsMenuItem.product;
                    } else {
                        $scope.futureProductLabel += ", " + futureProductsMenuItem.product;
                    }
                }
            });

            $scope.futureExpiryLabel = "Sélectionnez une échéance";
            $scope.futureExpiriesMenuItems.forEach(function (futureExpiriesMenuItem) {
                if (futureExpiriesMenuItem.checked) {
                    if ($scope.futureExpiryLabel === "Sélectionnez une échéance") {
                        $scope.futureExpiryLabel = futureExpiriesMenuItem.expiry;
                    } else {
                        $scope.futureExpiryLabel += ", " + futureExpiriesMenuItem.expiry;
                    }
                }
            });
        };

        var setPhysiqueMultiSelectValues = function () {
            $scope.physiqueProductLabel = "Sélectionnez un produit";
            $scope.physiqueProductsMenuItems.forEach(function (physiqueProductsMenuItem) {
                if (physiqueProductsMenuItem.checked) {
                    if ($scope.physiqueProductLabel === "Sélectionnez un produit") {
                        $scope.physiqueProductLabel = physiqueProductsMenuItem.product;
                    } else {
                        $scope.physiqueProductLabel += ", " + physiqueProductsMenuItem.product;
                    }
                }
            });
        };

        $scope.filterByClickFutureProductsMenuItem = function (idProduct, checked) {
            var futureProductsMenuItem = $filter('filter')($scope.futureProductsMenuItems, { idProduct: idProduct }, true)[0];
            futureProductsMenuItem.checked = !checked;
            var isAllUncheckedFutureProductsMenuItems = $filter('filter')($scope.futureProductsMenuItems, { checked: true }, true).length === 0;
            if (isAllUncheckedFutureProductsMenuItems) { //si les produits sont décochés, on affiche tout                    
                $scope.futureExpiriesMenuItems = [];
                $scope.futureProducts.forEach(function (itemFutureProduct) {
                    itemFutureProduct.visible = true;
                    itemFutureProduct.expiries.forEach(function (itemExpiry) {
                        itemExpiry.visible = true;
                        $scope.futureExpiriesMenuItems.push({ expiry: itemFutureProduct.product + ' ' + itemExpiry.expiry, product: itemFutureProduct.product, idExpiry: itemExpiry.idExpiry, idProduct: itemFutureProduct.idProduct, checked: false });
                    });
                });
            } else { //si au moins un produit est coché  
                $scope.futureProductsMenuItems.forEach(function (menuItemFutureProducts) {
                    var futureProduct = $filter('filter')($scope.futureProducts, { idProduct: menuItemFutureProducts.idProduct }, true)[0];
                    if (menuItemFutureProducts.checked) { //quand on coche le produit                         
                        //on ajoute les expiry du produits dans le menu expiry
                        futureProduct.expiries.forEach(function (itemExpiry) {
                            var futureExpiriesMenuItem = $filter('filter')($scope.futureExpiriesMenuItems, { idExpiry: itemExpiry.idExpiry }, true)[0];
                            if (!futureExpiriesMenuItem) {
                                $scope.futureExpiriesMenuItems.push({ expiry: futureProduct.product + ' ' + itemExpiry.expiry, product: futureProduct.product, idExpiry: itemExpiry.idExpiry, idProduct: futureProduct.idProduct, checked: false });
                            }
                        });
                    } else { // quand on décoche le produit                       
                        futureProduct.visible = false;
                        futureProduct.expiries.forEach(function (itemExpiry) {
                            var futureExpiriesMenuItem = $filter('filter')($scope.futureExpiriesMenuItems, { idExpiry: itemExpiry.idExpiry }, true)[0];
                            itemExpiry.visible = false;
                            if (futureExpiriesMenuItem) {
                                $scope.futureExpiriesMenuItems.splice($scope.futureExpiriesMenuItems.indexOf(futureExpiriesMenuItem), 1);
                            }
                        });
                    }
                });

                //on checke tous les items du menu expiry (tous produits confondus) pour voir si aucun est affiché, on doit afficher tous les blocs expiries
                var isAllUncheckedFutureExpiriesMenuItems = $filter('filter')($scope.futureExpiriesMenuItems, { checked: true }, true).length === 0;
                if (isAllUncheckedFutureExpiriesMenuItems) {
                    $scope.futureProducts.forEach(function (itemFutureProduct) {
                        var menuItemfutureProduct = $filter('filter')($scope.futureProductsMenuItems, { idProduct: itemFutureProduct.idProduct }, true)[0];
                        itemFutureProduct.visible = menuItemfutureProduct.checked;
                        if (itemFutureProduct.visible) {
                            itemFutureProduct.expiries.forEach(function (itemExpiry) {
                                itemExpiry.visible = true;
                            });
                        }
                    });
                }
            }
            setFutureMultiSelectValues();
        };

        $scope.filterByClickFutureExpiriesMenuItem = function (idProduct, idExpiry, checked) {
            var futureExpiriesMenuItemSelected = $filter('filter')($scope.futureExpiriesMenuItems, { idExpiry: idExpiry }, true)[0];
            futureExpiriesMenuItemSelected.checked = !checked;

            var futureProduct = $filter('filter')($scope.futureProducts, { idProduct: idProduct }, true)[0];
            var futureExpiry = $filter('filter')(futureProduct.expiries, { idExpiry: idExpiry }, true)[0];

            if (futureExpiriesMenuItemSelected.checked) {
                futureExpiry.visible = true;
                $scope.futureProducts.forEach(function (itemFutureProduct) {
                    itemFutureProduct.expiries.forEach(function (itemExpiry) {
                        var futureExpiriesMenuItem = $filter('filter')($scope.futureExpiriesMenuItems, { idExpiry: itemExpiry.idExpiry }, true)[0];
                        if (futureExpiriesMenuItem) {
                            itemExpiry.visible = futureExpiriesMenuItem.checked;
                        }
                    });

                    if ($filter('filter')(itemFutureProduct.expiries, { visible: true }, true).length > 0) {
                        itemFutureProduct.visible = true;
                    } else {
                        itemFutureProduct.visible = false;
                    }
                });

            } else {

                var isAllUncheckedFutureExpiriesMenuItems = $filter('filter')($scope.futureExpiriesMenuItems, { checked: true }, true).length === 0;
                var isAllUncheckedFutureProductsMenuItems = $filter('filter')($scope.futureProductsMenuItems, { checked: true }, true).length === 0;
                if (isAllUncheckedFutureExpiriesMenuItems) {
                    $scope.futureProducts.forEach(function (itemFutureProduct) {
                        var menuItemfutureProduct = $filter('filter')($scope.futureProductsMenuItems, { idProduct: itemFutureProduct.idProduct }, true)[0];
                        itemFutureProduct.visible = isAllUncheckedFutureProductsMenuItems ? isAllUncheckedFutureProductsMenuItems : menuItemfutureProduct.checked;
                        if (itemFutureProduct.visible) {
                            itemFutureProduct.expiries.forEach(function (itemExpiry) {
                                itemExpiry.visible = true;
                            });
                        }
                    });
                } else {
                    futureExpiry.visible = false;
                }
            }
            setFutureMultiSelectValues();
        };

        $scope.filterByClickPhysiqueProductsMenuItem = function (idProduct, checked) {
            var physiqueProductsMenuItem = $filter('filter')($scope.physiqueProductsMenuItems, { idProduct: idProduct }, true)[0];
            physiqueProductsMenuItem.checked = !checked;
            var isAllUncheckedPhysiqueProductsMenuItems = $filter('filter')($scope.physiqueProductsMenuItems, { checked: true }, true).length === 0;
            if (isAllUncheckedPhysiqueProductsMenuItems) {
                $scope.physiqueProducts.forEach(function (itemPhysiqueProduct) {
                    itemPhysiqueProduct.visible = true;
                    itemPhysiqueProduct.marketsExpiry.forEach(function (itemMarketExpiry) {
                        itemMarketExpiry.visible = true;
                    });
                });
            } else {
                $scope.physiqueProducts.forEach(function (itemPhysiqueProduct) {
                    var physiqueProductsMenuItemById = $filter('filter')($scope.physiqueProductsMenuItems, { idProduct: itemPhysiqueProduct.idProduct }, true)[0];
                    itemPhysiqueProduct.visible = physiqueProductsMenuItemById.checked;
                    itemPhysiqueProduct.marketsExpiry.forEach(function (itemMarketExpiry) {
                        itemMarketExpiry.visible = physiqueProductsMenuItemById.checked;
                    });
                });
            }
            setPhysiqueMultiSelectValues();
        };

        var initFutureQuotes = function () {
            $scope.futureProductLabel = 'Sélectionnez un produit';
            $scope.futureExpiryLabel = 'Sélectionnez une échéance';
            $scope.futureProducts = [];
            $scope.futureProductsMenuItems = [];
            $scope.futureExpiriesMenuItems = [];
            $scope.futureProductsActiveIdExpiry = 0;
            $scope.quotesUnit = $rootScope.globalLabels.Currency;
            marketinfosUserHashtagriService.GetFutureMarketQuotes(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.futureProductsError = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    data.forEach(function (item) {
                        var futureProduct = { product: item.ProductLabel, idProduct: item.IdMarket, expiries: [], visible: true };
                        $scope.futureProductsMenuItems.push({ product: item.ProductLabel, idProduct: item.IdMarket, checked: false });

                        item.LstHashtagriExpiryVms.forEach(function (itemExpiry) {
                            var expiryItem = { expiry: itemExpiry.ExpiryLabel, idExpiry: itemExpiry.IdExpiry, chge: itemExpiry.Variation, settle: itemExpiry.Quote, visible: true, expiryDate: itemExpiry.ExpiryDate };
                            if (itemExpiry.Variation > 0) {
                                expiryItem.chge = "+" + itemExpiry.Variation;
                                expiryItem.trend = 'positive';
                            } else if (itemExpiry.Variation < 0) {
                                expiryItem.trend = 'negative';
                            } else {
                                expiryItem.trend = 'neutral';
                            }
                            if ($scope.futureProductsActiveIdExpiry === 0)
                                $scope.setFutureProductsActiveIdExpiry(itemExpiry.IdExpiry, item.IdMarket);
                            futureProduct.expiries.push(expiryItem);
                            $scope.futureExpiriesMenuItems.push({ expiry: item.ProductLabel + ' ' + itemExpiry.ExpiryLabel, product: item.ProductLabel, idExpiry: itemExpiry.IdExpiry, idProduct: item.IdMarket, checked: false });
                        });

                        $scope.futureProducts.push(futureProduct);
                    });

                }
            });
        };

        var initPhysiqueQuotes = function () {
            $scope.physiqueProductLabel = 'Sélectionnez un produit';
            $scope.physiqueProducts = [];
            $scope.physiqueProductsMenuItems = [];
            $scope.physiqueProductsActiveIdExpiry = 0;
            $scope.quotesUnit = $rootScope.globalLabels.Currency;
            marketinfosUserHashtagriService.GetCashMarketQuotes(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.physiqueProductsError = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    data.forEach(function (item) {
                        var physiqueProduct = { product: item.ProductLabel, idProduct: item.IdProduct, marketsExpiry: [], visible: true };
                        $scope.physiqueProductsMenuItems.push({ product: item.ProductLabel, idProduct: item.IdProduct, checked: false });

                        item.LstHashtagriMarketExpiryVms.forEach(function (itemMarketExpiry) {
                            var marketExpiryItem = { expiry: itemMarketExpiry.ExpiryLabel, idExpiry: itemMarketExpiry.IdExpiry, chge: itemMarketExpiry.Variation, settle: itemMarketExpiry.Quote, visible: true, expiryDate: itemMarketExpiry.ExpiryDate, market: itemMarketExpiry.MarketLabel };
                            if (itemMarketExpiry.Variation > 0) {
                                marketExpiryItem.chge = "+" + itemMarketExpiry.Variation;
                                marketExpiryItem.trend = 'positive';
                            } else if (itemMarketExpiry.Variation < 0) {
                                marketExpiryItem.trend = 'negative';
                            } else {
                                marketExpiryItem.trend = 'neutral';
                            }
                            if ($scope.physiqueProductsActiveIdExpiry === 0)
                                $scope.setPhysiqueProductsActiveIdExpiry(itemMarketExpiry.IdExpiry, item.IdMarket);
                            physiqueProduct.marketsExpiry.push(marketExpiryItem);
                        });

                        $scope.physiqueProducts.push(physiqueProduct);
                    });

                }
            });
        };

        var initGlobalMacro = function () {
            $scope.globalMacro = null;
            marketinfosUserHashtagriService.GetGlobalMacro(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.physiqueProductsError = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.globalMacro = data;
                    $scope.globalMacro.PetrolBrentUnit = "$/Baril";
                    $scope.globalMacro.ForexEurUsdUnit = "$";
                    $scope.setGlobalMacroActiveGraph("PetrolBrent", $scope.globalMacro.PetrolBrentIdExpiry);
                    if ($scope.globalMacro.PetrolBrentVariation > 0) {
                        $scope.globalMacro.PetrolBrentVariation = "+" + $scope.globalMacro.PetrolBrentVariation;
                        $scope.globalMacro.PetrolBrentTrend = 'positive';
                    } else if ($scope.globalMacro.PetrolBrentVariation < 0) {
                        $scope.globalMacro.PetrolBrentTrend = 'negative';
                    } else {
                        $scope.globalMacro.PetrolBrentTrend = 'neutral';
                    }
                    if ($scope.globalMacro.ForexEurUsdVariation > 0) {
                        $scope.globalMacro.ForexEurUsdVariation = "+" + $scope.globalMacro.ForexEurUsdVariation;
                        $scope.globalMacro.ForexEurUsdTrend = 'positive';
                    } else if ($scope.globalMacro.ForexEurUsdVariation < 0) {
                        $scope.globalMacro.ForexEurUsdTrend = 'negative';
                    } else {
                        $scope.globalMacro.ForexEurUsdTrend = 'neutral';
                    }

                }
            });
        };

        var initWeatherNews = function () {
            $scope.WeatherNews = null;
            marketinfosUserHashtagriService.GetWeatherNews(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.weatherNewsError = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.WeatherNews = data;
                }
            });
        };

        var initUSDANews = function () {
            $scope.USDANews = null;
            marketinfosUserHashtagriService.GetUSDANews(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.USDANewsError = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    $scope.USDANews = data;
                }
            });
        };

        $scope.GetBlocDispositions = function () {
            var defered = $q.defer();
            $scope.IsBlocWeather = false;
            $scope.IsBlocUSDA = false;
            $scope.IsglobalMacro = false;
            $scope.IsphysiqueQuotes = false;
            $scope.IsfutureQuotes = false;
            blocDispositionService.GetBlocDispositionsFiltered(function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.quotesDisplayErrorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                } else {
                    defered.resolve(data);
                }
            });
            return defered.promise;
        };

        var getPathViewModeBlocDisposition = function (idContentBlocDisposition) {
            if (!idContentBlocDisposition)
                return '';

            switch (idContentBlocDisposition) {
                //Cotations Global Macro
                case $scope.contentBlocDispositions[0].Id:
                    $scope.IsglobalMacro = true;
                    return 'app/partials/frontoffice/marketinfos/ViewsMode/globalMacro.html';

                    //Cotations Physique
                case $scope.contentBlocDispositions[1].Id:
                    $scope.IsphysiqueQuotes = true;
                    return 'app/partials/frontoffice/marketinfos/ViewsMode/physiqueQuotes.html';

                    //Cotations Futures
                case $scope.contentBlocDispositions[2].Id:
                    $scope.IsfutureQuotes = true;
                    return 'app/partials/frontoffice/marketinfos/ViewsMode/futureQuotes.html';

                case $scope.contentBlocDispositions[3].Id:
                    $scope.IsBlocWeather = true;
                    return 'app/partials/frontoffice/marketinfos/ViewsMode/weather.html';

                case $scope.contentBlocDispositions[4].Id:
                    $scope.IsBlocUSDA = true;
                    return 'app/partials/frontoffice/marketinfos/ViewsMode/usda.html';
                default:
                    return '';
            }
        };

        $scope.parseHtmlContentForTakeStyleTag = function(content) {
            return content ? $sce.trustAsHtml(content) : "";
        };

        var init = function () {

            /*
             *News
             */

            var promise1 = $scope.GetNewsHashtagriFromWsdApi().then(
              function (data) {
                  $scope.userNewsHashtagri = data;
              },
               function (message) {
                   $scope.userNewsHashtagriErrorMessage = $sce.trustAsHtml(message);
               }
              );


            var promise2 = promise1.then(function (value) {
                $scope.GetNewsContentInfoMarketOS().then(
                function (data) {
                    $scope.userNewsInfoOs = data;
                    $scope.displayNewsInfoMarketOS = data && data.ContentsInfosMarketVm && data.ContentsInfosMarketVm.length > 0 ? true : false;

                }, function (message) {
                    $scope.userNewsHashtagriErrorMessage = $sce.trustAsHtml(message);
                }
            );
            });

            /*
             *NewsSynthesis and Technically Points
             */

            promise2.then(function (value) {
                $scope.GetCategoryInfoMarkets().then(
                    function (data) {
                        $scope.userCategoryInfoMarkets = data;
                        if (data && data.length > 0) {
                            $scope.GetMarketInfosCategHashtagriFromWsdApi($scope.userCategoryInfoMarkets[0]);
                            $scope.idCategoryInfoMarketSelected = $scope.userCategoryInfoMarkets[0].IdCategoryInfoMarket;
                        }
                    }, function (message) {
                        $scope.userCategoryInfoMarketsErrorMessage = $sce.trustAsHtml(message);
                    }
                );
            });

            /*
             *  Quotes + Bloc Dispositions
             */

            var promise3 = $scope.GetBlocDispositions().then(
              function (data) {
                  $scope.lstBlocDispositionsAlpha = [];
                  $scope.lstBlocDispositionsOmega = [];
                  if (data) {
                      data.forEach(function (item) {
                          if (item.IdContentBlocDisposition) {
                              item.PathViewMode = getPathViewModeBlocDisposition(item.IdContentBlocDisposition);
                              if (item.IdBlocDisposition >= 1 && item.IdBlocDisposition <= 3)
                                  $scope.lstBlocDispositionsAlpha.push(item);
                              else
                                  $scope.lstBlocDispositionsOmega.push(item);
                          }
                      });
                  }
              }
              );

            promise3.then(function () {
                if ($scope.IsfutureQuotes)
                    initFutureQuotes();
                if ($scope.IsphysiqueQuotes)
                    initPhysiqueQuotes();
                if ($scope.IsglobalMacro)
                    initGlobalMacro();
                if ($scope.IsBlocWeather)
                    initWeatherNews();
                if ($scope.IsBlocUSDA)
                    initUSDANews();
            });
        };

        $scope.DisplayContentInfoMarketOS = function (value) {
            $scope.contentMarketInfoCategOS.ContentsInfosMarketVm.forEach(function (item) {
                item.ShowContentInfoMarketOS = item.DateUUID === value;
            });
        };

        init();
    }]);
