AgrimarketControllers.controller('dashboardController', [
    '$scope', '$rootScope', '$http', '$filter', '$location', '$sce', 'dashboardService',
    'enumFactory', 'quoteService', 'quoteCashHashtagriService', 'configurationFactory', 'carouselPictureService', '$timeout',
    function ($scope, $rootScope, $http, $filter, $location, $sce, dashboardService, enumFactory, quoteService, quoteCashHashtagriService, configurationFactory, carouselPictureService, $timeout) {

        if (!$rootScope.ModuleNews || !$rootScope.ModuleNews.Active)
            $location.path("/");

        $scope.enumTypeDashboardActuality = enumFactory.TypeDashboardActuality;
        $scope.enumElementsInfoMarketHomePage = enumFactory.ElementsInfoMarketHomePage;
        $scope.currentQuotes = {};
        $scope.lstCarouselPictureLinks = [];

        $scope.GetDashboard = function () {
            dashboardService.GetDashboard(function (data, status) {
                if (status !== null) {
                    $scope.errorMessage = data[0].errors[0].Message;
                } else {                                      
                    $scope.dashboard = data;
                    if ($rootScope.User) $scope.userFullName = ($rootScope.User.SocialReason ? $rootScope.User.SocialReason + " " : "") + ($rootScope.User.FirstName ? $rootScope.User.FirstName : "") + " " + ($rootScope.User.LastName ? $rootScope.User.LastName : "");

                    $scope.GetContentFeeds();
                    $timeout(dashboardCarousel, 0);
                   
                    if ($rootScope.ModuleHashtagri.Active) {
                        $scope.quotesUnit = $rootScope.globalLabels.Currency;
                        $scope.lastDateMajQuotesInfoMarket = new Date();
                        $scope.UrlInfoMarket = "#/user/marketinfos/";
                    }
                }
            });
        };

        $scope.ConvertContentToHtml = function (newsContent) {
            return $sce.trustAsHtml(newsContent);
        };

        $scope.OpenArticle = function (item, popup) {
            $scope.Title = item.Title;
            $scope.selectednews = angular.copy(item);
            $scope.selectednews.Content = $scope.ConvertContentToHtml($scope.selectednews.Content);
            popup($scope.selectednews);
        };

        
        $scope.onClickResizeBloc = function (idTypeDashboardActuality) {
            if (idTypeDashboardActuality === $scope.enumTypeDashboardActuality.CAROUSEL)
                $timeout(refreshCarousel, 0);
        };

        $rootScope.onRefresh = function () {
            $scope.GetDashboard();
        };

        $scope.ChangePosition = function (positionArray) {
            if (positionArray.Start === positionArray.End)
                return;

            $scope.lstBlocPosition = [];
            var pos = 1;
            positionArray.End.forEach(function (item) {
                item = JSON.parse(item);
                var typeBloc = parseInt(item.blocId.split('_')[0]);
                var idBloc = parseInt(item.blocId.split('_')[1]);
                if (typeBloc === $scope.enumTypeDashboardActuality.NEWS || typeBloc === 9 || typeBloc === $scope.enumTypeDashboardActuality.CAROUSEL || typeBloc === $scope.enumTypeDashboardActuality.CONTACTS )
                    idBloc = null;

                var infoBloc = {
                    IdBloc: idBloc,
                    OpenBloc: item.size,
                    Position: pos,
                    IdTypeDashboardActuality: typeBloc
                };
                pos++;
                $scope.lstBlocPosition.push(infoBloc);
            });
            dashboardService.PostPositionBlocs($scope.lstBlocPosition, function (data, status) {
                if (status !== null)
                    $scope.errorMessage = data[0].errors[0].Message;
            });
        };

        $scope.GetContentFeeds = function () {
            $scope.dashboard.Blocs.forEach(function (bloc) {
                if (bloc.IdTypeDashboardActuality === $scope.enumTypeDashboardActuality.RSS) {
                    var url = "//api.rss2json.com/v1/api.json?callback=JSON_CALLBACK&rss_url=" + encodeURIComponent(bloc.UrlFeed);
                    $http.jsonp(url).success(function (data) {
                        bloc.Flux = [];
                        var maxFlux = 2;
                        if (data.items[0].content === "")
                            maxFlux = 4;
                        data.items.forEach(function (article) {
                            if (maxFlux > 0) {
                                var fluxTmp = {
                                    Title: article.title,
                                    Content: article.description,
                                    Date: new Date(article.pubDate),
                                    Link: article.link
                                };
                                if (article.pubDate === "")
                                    fluxTmp.Date = "";
                                bloc.Flux.push(fluxTmp);
                                maxFlux--;
                            }
                        });
                    }).error(function () {
                        bloc.Flux = [{
                            Title: "Erreur: Ce flux est actuellement indisponible."
                        }];

                    });
                }
            });
        };

        $scope.GetDashboard();

        $scope.GetDataSeriesQuotes = function (idExpiry, idMarket, typeQuotes) {
            var id = idExpiry + '_' + idMarket + '_' + typeQuotes;
            switch (typeQuotes) {
                case "F":
                    quoteService.GetSettleDataFromEuronext(idExpiry, idMarket, true, function (dataFuture, status) {
                        if (status !== null) {
                            if (status === 400) {
                                $scope.errorMessage = dataFuture[0].errors[0].Message;
                            }
                        } else {
                            var seriesFuture = [];

                            dataFuture.forEach(function (item) {
                                var date = new Date(parseInt(item.DateTimeSpan));
                                seriesFuture.push([Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), item.Settle, new Date()]);
                            });
                            $scope.currentQuotes.QuotesCurrentdataChartSeries = seriesFuture;
                        }
                    });
                    break;
                case "C":
                    quoteCashHashtagriService.GetHistoCashForGraphByIdExpiryFromWsdApi(idExpiry, function (dataCash, status) {
                        if (status !== null) {
                            if (status === 400)
                                $scope.errorMessage = dataCash[0].errors[0].Message;
                        } else {
                            var seriesCash = [];
                            dataCash.forEach(function (item) {
                                var date = new Date(parseInt(item.DateDayTimeSpan));
                                seriesCash.push([Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()), item.settle, new Date()]);
                            });
                            $scope.currentQuotes.QuotesCurrentdataChartSeries = seriesCash;
                        }
                    });
                    break;
            }
            $scope.quoteId = id;
        };
    }]);
